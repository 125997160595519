import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, nextPage: Number, lastPage: Number }
  static targets = ["table", "button", "spinner"]

  fetch() {
    this.#toggleLoad()

    const url = `${this.urlValue}&page=${this.nextPageValue}`
    fetch(url, {
      headers: {
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then(html => {
        this.tableTarget.insertAdjacentHTML("beforeend", html)
        this.#toggleLoad()
        const nextPage = this.nextPageValue + 1;
        
        if (nextPage > this.lastPageValue) {
          this.buttonTarget.classList.add("d-none")
        } else {
          this.element.dataset.loadMoreNextPageValue = nextPage
        }
      })
  }

  sort(event) {
    event.preventDefault()
    const url = event.target.href

    fetch(url, {
      headers: {
        "Accept": "text/plain"
      }
    })
      .then(response => response.text())
      .then(html => {
        this.element.outerHTML = html
      })
  }

  #toggleLoad() {
    this.buttonTarget.classList.toggle("d-none");
    this.spinnerTarget.classList.toggle("d-none");
  }
}

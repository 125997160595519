import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "name", 
    "email", 
    "mobile", 
    "notes", 
    "sendEmailBtn", 
    "shareContactBtn",
    "saveContactBtn",
    "saveContactBtnIcon",
    "saveContactBtnText",
    "saveContactAnchor",
    "spinner",
    "downloadingToast",
    "form",
    "scanBtn"
  ]
  
  static values = { 
    recipientEmail: String, 
    alert: String, 
    emailUrl: String, 
    cardToken: String,
    loadingText: String,
    finishedText: String,
    sharedText: String
  }

  createConnection(event) {
    event.preventDefault()

    const form = event.target

    this.#sendConnection(form)
    this.#sendEmailToLead()
  }

  saveContact() {
    this.#setStepAsLoading()
    if (this.hasShareContactBtnTarget) {
      if (!this.shareContactBtnTarget.classList.contains("bg-green")) this.#highlightNextStep();
    }

    this.#showDownloadingToast()
    
    setTimeout(() => {
      this.#setStepAsFinished()
      this.#sendVcf()
    }, 1500)
  }

  fillFields() {
    const commonDomains = [
      "gmail",
      "yahoo",
      "hotmail",
      "aol",
      "outlook",
      "comcast",
      "icloud",
      "msn",
      "live",
      "sapo",
      "me",
      "att",
      "mail",
      "online",
      "orange"
    ]

    const email = this.emailTarget.value;

    const domain = email.match(/(?<=@)[^.]+(?=\.)/)?.[0]?.toLowerCase();
    const name = email.split('@')?.[0]?.split(/[^a-zA-Z]+/)?.join(' ');

    this.nameTarget.value = this.#titleize(name);

    if (!commonDomains.includes(domain)) {
      this.notesTarget.value = this.#titleize(domain);
    } else {
      this.notesTarget.value = ""
    }
  }

  focusEmailInput() {
    const emailInput = document.getElementById("email");

    this.#focusAndOpenKeyboard(emailInput, 50)
  }

  // ------------------------
  //      AUX FUNCTIONS
  // ------------------------
  #sendEmailToLead() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(this.emailUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'text/plain',
      },
      body: new FormData(this.formTarget)
    })
  }

  #sendVcf() {
    this.saveContactAnchorTarget.click()
  }

  #sendConnection(form) {
    const formData = new FormData(form)

    formData.set("connection[email]", formData.get("email"))
    formData.set("connection[name]", formData.get("name"))
    formData.set("connection[mobile]", formData.get("mobile"))

    formData.delete("email")
    formData.delete("name")
    formData.delete("mobile")

    fetch(form.action, {
      method: 'POST',
      headers: {
        'Accept': this.fetchAcceptValue || 'text/plain',
      },
      body: formData
    })
      .then(response => response.text())
      .then((data) => {  
        form.outerHTML = data
        
        if(document.querySelector(".invalid-feedback") == null) {
          this.#closeModal()
          this.#setShareAsFinished()

          document.body.insertAdjacentHTML("beforeend", `<div class="notice alert alert-info alert-dismissible fade show m-1 shadow rounded-3-5" role="alert" style="bottom: 100px;">
            <div class="d-flex align-items-center justify-content-start gap-3">
              <i class="fa-solid fa-circle-check fs-1"></i>
              <p class="m-0">${this.alertValue}</p>
            </div>
            <button type="button" class="wallim-btn-close green-color" data-bs-dismiss="alert" aria-label="Close">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>`)

          this.nameTarget.value = ""
          this.nameTarget.style.backgroundImage = "none"
          this.nameTarget.classList.remove("is-valid")

          this.emailTarget.value = ""
          this.emailTarget.style.backgroundImage = "none"
          this.emailTarget.classList.remove("is-valid")

          this.mobileTarget.value = ""
          this.mobileTarget.style.backgroundImage = "none"
          this.mobileTarget.classList.remove("is-valid")

          this.notesTarget.value = ""
          this.notesTarget.style.backgroundImage = "none"
          this.notesTarget.classList.remove("is-valid")
        } else {
          this.scanBtnTarget.style.setProperty("margin-bottom", "40px", "important")
        }
      })
  }

  #closeModal() {
    const modalBackdrop = document.querySelector(".modal-backdrop")
    modalBackdrop.remove()

    this.element.classList.remove("show")
    this.element.style.display = "none"
    this.element.removeAttribute("aria-modal")
    this.element.removeAttribute("role")
    this.element.setAttribute("aria-hidden", "true")

    document.body.style.overflow = null
    document.body.style.paddingRight = null
    document.body.classList.remove("modal-open")
  }

  #highlightNextStep() {
    this.shareContactBtnTarget.classList.add("pulsing-btn");
    this.shareContactBtnTarget.classList.add("wallim-primary-btn");
    this.shareContactBtnTarget.classList.remove("wallim-secondary-btn");
  }
  
  #setStepAsLoading() {
    this.saveContactBtnTarget.classList.remove("pulsing-btn");
    this.saveContactBtnTextTarget.innerText = `${this.loadingTextValue}...`;
    this.saveContactBtnIconTarget.classList.add("d-none");
    this.spinnerTarget.classList.remove("d-none");
  }
  
  #setStepAsFinished() {
    this.saveContactBtnTarget.classList.add("bg-green");
    this.saveContactBtnTarget.classList.add("opacity-75");
    this.saveContactBtnTarget.classList.add("white-color");
    this.saveContactBtnIconTarget.classList.remove("d-none");
    this.spinnerTarget.classList.add("d-none");
    this.saveContactBtnTextTarget.innerText = `${this.finishedTextValue}`;
  }

  #setShareAsFinished() {
    const shareContactBtn = document.getElementById("share-contact-btn");
    const shareContactBtnIcon = document.getElementById("share-contact-btn-icon");
    const shareContactBtnText = document.getElementById("share-contact-btn-text");

    shareContactBtn.classList.remove("pulsing-btn");
    shareContactBtn.classList.add("bg-green");
    shareContactBtn.classList.add("opacity-75");
    shareContactBtn.classList.add("white-color");
    shareContactBtnIcon.classList.remove("d-none");
    shareContactBtnText.innerText = `${this.sharedTextValue}`;
  }

  #showDownloadingToast() {
    if (this.hasDownloadingToastTarget) {
      this.downloadingToastTarget.classList.remove("d-none");
    }
  }

  #focusAndOpenKeyboard(el, timeout) {
    if(!timeout) {
      timeout = 100;
    }
    if(el) {
      // Align temp input element approximately where the input element is
      // so the cursor doesn't jump around
      var __tempEl__ = document.createElement('input');
      __tempEl__.style.position = 'absolute';
      __tempEl__.style.top = (el.offsetTop + 7) + 'px';
      __tempEl__.style.left = el.offsetLeft + 'px';
      __tempEl__.style.height = 0;
      __tempEl__.style.opacity = 0;
      // Put this temp element as a child of the page <body> and focus on it
      document.body.appendChild(__tempEl__);
      __tempEl__.focus();
  
      // The keyboard is open. Now do a delayed focus on the target element
      setTimeout(function() {
        el.focus();
        el.click();
        // Remove the temp element
        document.body.removeChild(__tempEl__);
      }, timeout);
    }
  }

  #titleize(string) {
    if (!string) return "";

    const splitStr = string.toLowerCase().split(' ');

    const titleizedString = splitStr.map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1);     
    })

    return titleizedString.join(' '); 
  }
}

import { Controller } from "stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = {
    tapsData: Array,
    leadsData: Array,
    usersData: Array,
    leadsPerTapsData: Array,
    tapsPerUsersData: Array,
    leadsPerUsersData: Array,
  };

  static targets = [
    "usersChart",
    "employeesChart",
    "leadsChart",
    "tapsChart",
    "leadsPerTapsChart",
    "leadsAndTapsChart",
    "tapsPerUsersChart",
    "leadsPerUsersChart",
  ];

  connect() {
    if (this.hasUsersChartTarget) {
      this.#createChart(
        this.usersChartTarget,
        this.usersDataValue,
        "Users",
        "bar",
        { stacked: true }
      );
    }

    if (this.hasTapsPerUsersChartTarget) {
      this.#createChart(
        this.tapsPerUsersChartTarget,
        this.tapsPerUsersDataValue,
        "Taps p/ Users",
        "line",
        {
          backgroundColor: "#FFB1C1",
          borderColor: "#FF6384",
        }
      );
    }

    if (this.hasLeadsPerUsersChartTarget) {
      this.#createChart(
        this.leadsPerUsersChartTarget,
        this.leadsPerUsersDataValue,
        "Leads p/ Users",
        "line"
      );
    }

    if (this.hasLeadsPerTapsChartTarget) {
      this.#createChart(
        this.leadsPerTapsChartTarget,
        this.leadsPerTapsDataValue,
        "LTR (Leads per Taps Ratio)",
        "line",
        {
          backgroundColor: "#C6ECAE",
          borderColor: "#585123",
        }
      );
    }

    if (this.hasLeadsChartTarget) {
      this.#createChart(
        this.leadsChartTarget,
        this.leadsDataValue,
        "Leads",
        "line",
        {
          borderColor: "#36A2EB",
          backgroundColor: "#9BD0F5",
        }
      );
    }

    if (this.hasTapsChartTarget) {
      this.#createChart(
        this.tapsChartTarget,
        this.tapsDataValue,
        "Taps",
        "line",
        {
          backgroundColor: "#FFB1C1",
          borderColor: "#FF6384",
        }
      );
    }

    if (this.hasLeadsAndTapsChartTarget) {
      const leadsAndTapsData = [
        {
          type: "line",
          label: "Taps",
          data: this.tapsDataValue.map((row) => row.value),
          backgroundColor: "#FFB1C1",
          borderColor: "#FF6384",
          fill: true,
        },
        {
          type: "line",
          label: "Leads",
          data: this.tapsDataValue.map((tap) => {
            if (
              this.leadsDataValue.map((row) => row.period).includes(tap.period)
            ) {
              return this.leadsDataValue.find(
                (lead) => lead.period === tap.period
              ).value;
            } else {
              return 0;
            }
          }),
          borderColor: "#36A2EB",
          backgroundColor: "#9BD0F5",
          fill: true,
        },
      ];
  
      const labels = this.tapsDataValue.map((row) => row.period);
      this.#createAreaStackedChart(
        this.leadsAndTapsChartTarget,
        leadsAndTapsData,
        labels,
        "Taps & Leads"
      );
    }
  }

  #createChart(
    chart,
    data,
    label,
    type,
    options = {
      borderColor: "#36A2EB",
      backgroundColor: "#9BD0F5",
      stacked: false,
    }
  ) {
    let chartData = {
      labels: data.map((row) => row.period),
      datasets: [
        {
          type,
          label,
          data: data.map((row) => row.value),
          ...options,
        },
      ],
    };

    if (options.stacked) {
      chartData = {
        labels: data[0].data.map((row) => row.period),
        datasets: data.map((group) => {
          return {
            type: "bar",
            label: group.label,
            data: group.data.map((row) => row.value),
          };
        }),
      };
    }

    this.tapsChart = new Chart(chart, {
      data: chartData,
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });
  }

  #createAreaStackedChart(chart, data, labels, title) {
    let chartData = {
      labels,
      datasets: data,
    };

    this.stackedChart = new Chart(chart, {
      data: chartData,
      options: {
        plugins: {
          title: {
            display: true,
            text: title,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: false,
          },
        },
      },
    });
  }
}
